<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <form>
        <div class="row">
          <div
            class="form-group col-12"
            :class="{ 'has-error': errors.has('categoryId') }"
          >
            <label>
              {{ $t("categories") }}
            </label>
            <v-select
              name="categoryId"
              v-model="selectedCategory"
              v-validate="'required'"
              label="name"
              :options="categoryList"
              :reduce="category => category.id"
            ></v-select>
            <small v-show="errors.has('categoryId')" class="text-danger">
              {{ $t("field_required") }}
            </small>
          </div>
          <div
            class="form-group col-12"
            :class="{ 'has-error': errors.has('productId') }"
          >
            <label>
              {{ $t("products") }}
            </label>
            <textarea
              class="form-control"
              name="productId"
              v-model="selectedProductsSymbol"
              v-validate="'required|duplicate_plum'"
              rows="5"
              @input="changeProductPlum()"
            >
            </textarea>
            <small v-show="errors.has('productId')" class="text-danger">
              {{ $t(errors.first("productId")) }}
            </small>
          </div>
        </div>
        <div class="form-group row mt-4">
          <div class="col-sm-12">
            <a
              class="btn btn-primary btn-sm text-white"
              @click="handleSubmit()"
            >
              {{ $t("download_products") }}
            </a>
          </div>
        </div>
      </form>
    </div>
    <div v-if="showDataTablesWithProducts == true" class="col-12">
      <div class="hr-line-dashed"></div>
      <div v-if="notExistPlums.length > 0">
        <div style="color: red">{{ this.messagesNotExistPlums }}</div>
        <div class="hr-line-dashed"></div>
      </div>
      <data-table
        ref="dataTable"
        :url="productListUrl"
        :manualColumns="manualColumns"
        :filterRowShow="false"
        :paginationShow="false"
        @loading="loadData"
        @downloadedData="checkPlumsList"
      />

      <div class="text-right">
        <a
          class="btn text-white btn-w-m btn-primary"
          :class="{ disabled: disabledSaveButton === true }"
          @click="saveProductInCategory()"
        >
          {{ $t("save") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable/DataTable.vue";

export default {
  name: "AddProductToCategoryForm",
  data() {
    return {
      manualColumns: [
        {
          key: "selected",
          name: "Wybór",
          type: "btn",
          show: true,
          filter: "btn",
          class: "text-center",
          touch: true,
          dont_refresh: true,
          function: (row, data) => this.changeCheckedProduct(row, data),
          btn: (a, row) =>
            `<input type="checkbox" id="product-${a}" name="product-${a}" ${
              row.selected ? "checked" : ""
            }>`
        },
        {
          key: "id",
          name: "ID",
          show: true,
          type: "text",
          filter: ""
        },
        {
          key: `name`,
          name: "Nazwa",
          show: true,
          type: "text",
          filter: ""
        },
        {
          key: "plum",
          name: "Plum",
          show: true,
          type: "text",
          filter: ""
        }
      ],
      categoryList: [],
      selectedCategory: null,
      selectedProductsSymbol: [],
      selectedProductIds: [],
      disabledSaveButton: true,
      productListUrl: "",
      showDataTablesWithProducts: false,
      notExistPlums: []
    };
  },
  props: {},
  async created() {
    await this.getCategoryList();
    this.$emit("loading", false);
  },
  computed: {
    categoryListUrl() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/categories`;
    },
    addCategoryUrl() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/products/add-categories`;
    },
    messagesNotExistPlums() {
      return this.notExistPlums.length > 0
        ? `${this.$t(
            "not_exist_product_with_plums"
          )} : ${this.notExistPlums.join(", ")}`
        : "";
    }
  },
  methods: {
    changeProductPlum() {
      this.showDataTablesWithProducts = false;
      this.notExistPlums = [];
    },
    convertString(text) {
      return text
        .replace(/['".]+/g, "")
        .replace(/\s+/g, ",")
        .replace(/,+/g, ",")
        .trim();
    },
    checkPlumsList(tableData) {
      let productSymbolsText = this.convertString(this.selectedProductsSymbol);
      let uncheckedPlumsArray = this.convertStringToArray(productSymbolsText);
      if (uncheckedPlumsArray) {
        let checkedPlumsArray = tableData.map(element => {
          return element.plum;
        });

        this.notExistPlums = uncheckedPlumsArray.filter(
          x => !checkedPlumsArray.includes(x)
        );
        this.updateSelectedProductIds(tableData);
        this.updateDisabledSaveButton();
      }
    },
    updateSelectedProductIds(table) {
      this.selectedProductIds = [];
      table.forEach(element => {
        if (element.selected == 1) {
          this.selectedProductIds.push(element.id);
        }
      });
    },
    updateDisabledSaveButton() {
      this.disabledSaveButton =
        this.selectedProductIds.length > 0 ? false : true;
    },
    convertStringToArray(text) {
      const sparseArray = text.split(",");
      const cleanArray = sparseArray.filter(Boolean);
      return cleanArray;
    },
    getCategoryList() {
      this.$emit("loading", true);
      return this.$http
        .get(this.categoryListUrl)
        .then(resp => {
          this.categoryList = resp.data.data;
          this.$emit("loading", false);
          return this.categoryList;
        })
        .catch(() => {
          this.$emit("loading", false);
          return false;
        });
    },
    changeCheckedProduct(row, data) {
      data.selected = data.selected == 1 ? 0 : 1;
      this.updateSelectedProductIds(this.$refs.dataTable.table_list);
      this.updateDisabledSaveButton();
    },

    loadData(bool) {
      this.$emit("loading", bool);
    },
    restartForm() {
      this.selectedCategory = null;
      this.selectedProductsSymbol = [];
      this.selectedProductIds = [];
      this.disabledSaveButton = true;
      this.productListUrl = "";
      this.showDataTablesWithProducts = false;
      this.notExistPlums = [];
      this.$validator.reset();
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result == true) {
          let paramProductSymbols = this.convertString(
            this.selectedProductsSymbol
          );
          this.productListUrl = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/products-table-list?productSymbols=${paramProductSymbols}`;
          this.showDataTablesWithProducts = true;
        }
      });
    },
    saveProductInCategory() {
      this.$emit("loading", true);
      let dataToSave = {
        categoryId: this.selectedCategory,
        productIds: this.selectedProductIds
      };
      this.$http
        .post(this.addCategoryUrl, dataToSave)
        .then(() => {
          this.$emit("loading", false);
          this.restartForm();
          this.$toastr.success(this.$t("added_product_to_category"));
        })
        .catch(() => {
          this.$emit("loading", false);
          this.$toastr.error(this.$t("error_added_product_to_category"));
        });
    }
  },
  components: {
    DataTable
  }
};
</script>
