<template>
  <Ibox :title="$t('add-products-to-the-category')">
    <template slot="content" slot-scope="{ setLoading }">
      <add-product-to-category-form @loading="setLoading" />
    </template>
  </Ibox>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import AddProductToCategoryForm from "@/components/Products/AddProductToCategory/AddProductToCategory.vue";

export default {
  name: "AddProductToCategory",
  components: {
    Ibox,
    AddProductToCategoryForm
  }
};
</script>
